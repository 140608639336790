import { graphql } from "gatsby";
import { navigate } from "gatsby-link";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import MetaTags from "../components/MetaTags";
import branchesLargeImage from "../img/branches-large.svg";
import tallCloud from "../img/tall-cloud.png";
import shortCloud from "../img/short-cloud.png";

import topImage from "../img/kt.jpg";
import rightImage from "../img/vr-show.jpg";
import bottomImage from "../img/chair.jpg";
import leftImage from "../img/workstation.jpg";

import laptopImage from "../img/laptop.svg";
import rocketImage from "../img/rocket-white.svg";
import vrHeadImage from "../img/vr-head-black.svg";
import gogglesImage from "../img/wrapped-goggles.png";

import friendImage from "../img/friend-white.svg";
import boundlessImage from "../img/boundless-black.svg";

import ty from "../styled-tachyons";
import {
  BodyText,
  Button,
  Card,
  ColHalf,
  DarkGraySection,
  FlexRow,
  GraySection,
  Image,
  lQuery,
  nsQuery,
  PageHeader,
  RedSection,
  rem,
  Section,
  SectionHeader,
  SubHeader,
} from "../styles";

const LargeText = styled.h4`
  ${ty`f4 tc mb3`}
`;

const DonationSection = styled(DarkGraySection)`
  ${FlexRow} {
    ${ty`center`}
    max-width: ${rem(1105)};
    ${Card} {
      ${ty`flex flex-column ph4-l mw6 w-100 w-third-l mb4 mb0-l`}
      &:not(:last-child) {
        ${ty`mr3-l`}
      }
      ${Image} {
        height: ${rem(120)};
      }
      ${BodyText} {
        flex-grow: 1;
      }
      ${Button} {
        ${lQuery} {
          margin-top: auto;
        }
        ${ty`ph5 mt3`};
      }
    }
  }

  ${PageHeader} + ${BodyText} {
    ${ty`mt0`}
  }

  ${SectionHeader}, ${PageHeader} {
    font-family: nunito, sans-serif;
  }
`;

const LargeCard = styled.div`
  max-width: ${rem(1105)};
  ${ty`center mv5 pt5 pb4 tc ph3`}
  ${SectionHeader} {
    ${ty`flex justify-center tc`}
    ${Image} {
      ${ty`dib ma0 mr3`}
    }
  }
  ${BodyText} {
    ${ty`f6`}
  }
`;

const FriendSection = styled(LargeCard)`
  ${ty`bg-dark-gray white`}
  ${BodyText} {
    ${ty`mw6`}
  }

  ${FlexRow} {
    max-width: ${rem(700)};
    ${ty`mt4 justify-between-ns content-center flex-column flex-row-ns`};
  }
  ${Button} {
    ${ty`w4 db dib-ns mb3 mb0-ns`}
  }
`;
const BoundlessSection = styled(LargeCard)`
  ${ty`bg-light-gray near-black`}
  ${SectionHeader} {
    ${ty`ttn`}
  }
  ${BodyText} {
    max-width: ${rem(653)};
  }
  ${Button} {
    ${ty`mt4`}
  }
`;

const OtherWaysSection = styled(Section)`
  ${SectionHeader} {
    ${ty`washed-red`}
  }

  ul {
    ${ty`center f4 list pl0`}
    max-width: 40.3125rem;
    ${lQuery} {
      /* padding-right: 15rem; */
    }
    counter-reset: section;
    li {
      counter-increment: list;
      font-family: prompt, sans-serif;
      height: ${rem(50)};

      ${ty`flex items-center mb3`}
    }
    li:before {
      ${ty`mr3 br-pill bg-black white tc inline-flex flex-column justify-center items-center f2`}
      flex-shrink: 0;
      content: counter(list);
      height: ${rem(50)};
      width: ${rem(50)};
    }
  }
`;
const InstitutionsSection = styled(RedSection)`
  ${ColHalf}:first-child {
    padding: 0 2.35rem;
    ${ty`flex-ns flex-column-ns justify-center-ns`}

    ${BodyText} {
      ${ty`center`}
      max-width: 85%;
    }
  }
`;

const EquipmentSection = styled(GraySection)`
  ${BodyText} {
    max-width: ${rem(850)};
  }
  ${Button} {
    ${ty`ph5 mt3`};
  }
  .graphic-container {
    ${ty`mt5`}
    ${lQuery} {
      position: relative;
      min-height: 650px;
      max-height: 750px;
      height: 100vh;
    }
    .cloud {
      display: block;
      text-decoration: none;
      color: inherit;
    }
    .cloud,
    .branches {
      ${ty`absolute-l mb5 ma0-l`}
    }
    ${Image} {
      ${ty`mb2 w-100-ns`};
    }

    ${SectionHeader} {
      ${ty`ttn f4`}
    }
    ${SubHeader} {
      ${ty`washed-red f6 f5-l`}
    }
  }
  .top-cloud {
    ${Image} {
      max-width: 140px;
    }
  }
  .right-cloud {
    ${Image} {
      max-width: 162px;
    }
  }
  .bottom-cloud {
    ${Image} {
      max-width: 139px;
    }
  }
  .left-cloud {
    ${Image} {
      max-height: 120px;
      max-width: 169px;
    }
  }
  .branches {
    pointer-events: none;
    ${ty`dn db-l`};
    ${Image} {
      max-width: 534px;
    }
  }
  ${lQuery} {
    .top-cloud {
      ${ty`top-0-ns`};
      left: 55%;
      transform: translateX(-50%);
      min-width: ${rem(348)};
    }
    .right-cloud {
      right: 0;
      left: 66%;
      bottom: 50%;
      transform: translateY(50%);
    }
    .bottom-cloud {
      ${ty`bottom-0-ns`};
      left: 45%;
      transform: translateX(-50%);
      ${Image} {
        ${ty`pl3`}
      }
    }
    .left-cloud {
      left: 0;
      right: 66%;
      bottom: 50%;
      transform: translateY(50%);
      ${Image} {
        ${ty`pl3`}
        max-width: ${rem(169)}
      }
    }

    .tall-cloud-image,
    .short-cloud-image {
      &:after {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
      position: relative;
      display: flex;
      align-items: center;
      ${Image} {
        max-width: 162px;
        max-height: 125px;
        width: auto;
        height: auto;
      }
    }
    .flip-x {
      &:after {
        transform: scaleX(-1);
      }
    }
    .flip-y {
      &:after {
        transform: scaleY(-1);
      }
    }
    .tall-cloud-image {
      min-height: ${rem(245)};
      &:after {
        background-image: url(${tallCloud});
      }
    }
    .short-cloud-image {
      min-height: ${rem(178)};
      &:after {
        background-image: url(${shortCloud});
      }
    }

    .branches {
      ${ty`flex content-center`};
      left: 29%;
      top: 50%;
      bottom: 13%;
      right: 30%;

      transform: translateY(-50%);
    }
  }
`;

const DonorListSection = styled(DarkGraySection)`
  ${SubHeader} {
    ${ty`ttu pt5`};
    font-family: nunito, sans-serif;
  }
`;

const DonorParagraph = styled(BodyText)`
  ${ty`f6`}
`;

const StrokeText = styled.div`
  display: inline-block;
  ${nsQuery} {
    ${ty`f1`};
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: transparent;
  }
`;

const DonatedBanner = styled.h2`
  ${ty`flex-ns justify-center items-center mb5 tc v-base`}
  span {
    ${ty`washed-red ttu lh-title ml2-ns f4 f6-ns`}
  }
`;

export const GetInvolvedPageTemplate = ({
  totalDonated,
  visionaries,
  futurists,
  supporters,
  friends,
}) => {
  const visionariesDisplay = visionaries.map((visionary, i) => {
    return <DonorParagraph key={i}>{visionary.name}</DonorParagraph>;
  });
  let visionariesSection = (
    <>
      <SubHeader>Visionaries</SubHeader>
      {visionariesDisplay}
    </>
  );
  if (
    visionaries.length === 0 ||
    (visionaries.length === 1 && visionaries[0].name === "placeholder")
  ) {
    visionariesSection = null;
  }

  const futuristsDisplay = futurists.map((futurist, i) => {
    return <DonorParagraph key={i}>{futurist.name}</DonorParagraph>;
  });
  let futuristsSection = (
    <>
      <SubHeader>Futurists</SubHeader>
      {futuristsDisplay}
    </>
  );
  if (
    futurists.length === 0 ||
    (futurists.length === 1 && futurists[0].name === "placeholder")
  ) {
    futuristsSection = null;
  }

  const supportersDisplay = supporters.map((supporter, i) => {
    return <DonorParagraph key={i}>{supporter.name}</DonorParagraph>;
  });
  let supportersSection = (
    <>
      <SubHeader>Supporters</SubHeader>
      {supportersDisplay}
    </>
  );
  if (
    supporters.length === 0 ||
    (supporters.length === 1 && supporters[0].name === "placeholder")
  ) {
    supportersSection = null;
  }

  const friendsDisplay = friends.map((friend, i) => {
    return <DonorParagraph key={i}>{friend.name}</DonorParagraph>;
  });
  let friendsSection = (
    <>
      <SubHeader>Friends</SubHeader>
      {friendsDisplay}
    </>
  );
  if (
    friends.length === 0 ||
    (friends.length === 1 && friends[0].name === "placeholder")
  ) {
    friendsSection = null;
  }

  const onClick = (name) => {
    navigate(`/donate?level=${name}`);
  };

  return (
    <>
      <MetaTags title={"Get Involved"} />
      <DonationSection>
        <DonatedBanner>
          <StrokeText>${totalDonated}</StrokeText>{" "}
          <span>has been donated to support artist projects</span>
        </DonatedBanner>
        <PageHeader>Want to support Art Reality Studio?</PageHeader>
        <BodyText fontSize="medium">
          ARS supporters are curious about what’s next, but aren’t content
          waiting for it to happen.
        </BodyText>
        <FlexRow ty="mt4 mt5-ns flex-nowrap-l" htmlId="donate">
          <Card css={ty`bg-white black ba b--red`}>
            <SectionHeader>Supporter</SectionHeader>
            <Image src={laptopImage} />
            <LargeText>Donate $50 to $2,499</LargeText>
            <BodyText>
              Play a crucial role in enabeling new works to be developed and
              fostering educaton and discussion about the role of technology in
              society.
            </BodyText>
            <Button onClick={() => onClick("supporter")} center ty="b--washed-red">
              Donate
            </Button>
          </Card>
          <Card css={ty`bg-red white ba b--white`}>
            <SectionHeader>Futurist</SectionHeader>
            <Image src={vrHeadImage} />
            <LargeText>Donate $2,500 to $4,999</LargeText>
            <BodyText>
              Support ARS or a specific artist project from $2,500-$4,999.
            </BodyText>
            <Button onClick={() => onClick("futurist")} ty="bg-red white b--near-black" center>
              Donate
            </Button>
          </Card>
          <Card css={ty`bg-near-black red ba b--white`}>
            <SectionHeader>Visionary</SectionHeader>
            <Image src={rocketImage} />
            <LargeText>$5,000 and above</LargeText>
            <Button
              onClick={() => onClick("visionary")}
              ty="bg-near-black red b--white"
              whiteshadow="true"
              center
            >
              Donate
            </Button>
            <LargeText css={ty`mt3 pt3 mb0 b--white bt`}>
              $5,000 Annually
            </LargeText>
            <BodyText>
              Contributions of $5,000 per year will be limited to 20 Sustaining
              Visionaries worldwide who want to ensure that artists have a role
              in determining how technology impacts society.
            </BodyText>
            <Button
              to={"/contact"}
              ty="bg-near-black red b--white"
              whiteshadow="true"
              center
            >
              Contact Us
            </Button>
          </Card>
        </FlexRow>
        <FriendSection>
          <SectionHeader>
            <Image src={friendImage} />
            Friend
          </SectionHeader>
          <BodyText>
            Join a community of supporters who believe artists should help
            illuminate technology’s impact on the world.
          </BodyText>
          <FlexRow>
            <Button onClick={() => onClick("friend5")} ty="b--washed-red">
              $5
            </Button>
            <Button
              onClick={() => onClick("friend10")}
              ty="bg-washed-red white b--near-black"
            >
              $10
            </Button>
            <Button
              onClick={() => onClick("friend20")}
              ty="bg-near-black washed-red b--white"
            >
              $20
            </Button>
          </FlexRow>
        </FriendSection>
        <BoundlessSection>
          <SectionHeader>
            <Image src={boundlessImage} />
            BOUNDLESS... More Ways to Give
          </SectionHeader>
          <BodyText>
            We value out of the box thinkers who want to expand patronage and
            philanthropy. We will happily collaborate with you to develop a
            giving plan that meets your interest and goals.
          </BodyText>
          <Button ty="bg-near-black washed-red b--white ph4" to="/contact">
            Contact Us
          </Button>
        </BoundlessSection>
      </DonationSection>
      <OtherWaysSection>
        <SectionHeader>Other Ways to Get Involved</SectionHeader>
        <ul>
          <li>Sponsor a participating artist’s project</li>
          <li>Collect a limited Artist Edition from a participating artist</li>
          <li>Donate equipment</li>
          <li>Offer training</li>
          <li>Participate in panels</li>
        </ul>
      </OtherWaysSection>
      <InstitutionsSection>
        <FlexRow>
          <ColHalf>
            <SectionHeader>
              Corporations, Foundations, Institutions
            </SectionHeader>
            <BodyText>
              Support for ARS can be made in the form of in kind products and
              services, grants, and programming partnerships.
            </BodyText>
            <Button center to="/contact" ty="bg-red white">
              Contact Us
            </Button>
          </ColHalf>
          <ColHalf>
            <Image src={gogglesImage} />
          </ColHalf>
        </FlexRow>
      </InstitutionsSection>
      <EquipmentSection>
        <SectionHeader>Where your Donations go</SectionHeader>
        <BodyText>
          Your tax deductible donations go directly to supporting the work of
          emerging and mid career artists in Los Angeles. Each work developed as
          part of the program, belongs to the artist. ARS does not take a
          portion of any sale of works.
        </BodyText>
        <BodyText>
          Our programming is dependent solely on the generosity of engaged
          donors like you.
        </BodyText>
        <Button
          to={"/donate"}
          ty="bg-near-black red b--white"
          whiteshadow="true"
          center
        >
          Donate
        </Button>
        <div className="graphic-container">
          <a
            href="https://www.arsnet.io/toolbox"
            target="_blank"
            rel="noreferrer"
            className="top-cloud cloud"
          >
            <div className="short-cloud-image flip-y">
              <Image src={topImage} />
            </div>
            <SectionHeader>Head Mounted Displays</SectionHeader>
            <SubHeader>Valve Index, HTC Vive</SubHeader>
          </a>
          <a
            href="https://www.arsnet.io/groups"
            target="_blank"
            rel="noreferrer"
            className="right-cloud cloud"
          >
            <div className="tall-cloud-image">
              <Image src={rightImage} />
            </div>
            <SectionHeader>
              Programming Support, Unique Technical Requirements &amp;
              Exhibitions
            </SectionHeader>
            <SubHeader>
              Tensor Flow, Volumetric Capture, Travel, Shipping, Construction
            </SubHeader>
          </a>
          <a
            href="https://www.arsnet.io/toolbox"
            target="_blank"
            rel="noreferrer"
            className="bottom-cloud cloud"
          >
            <div className="short-cloud-image">
              <Image src={bottomImage} />
            </div>
            <SectionHeader>Experiential Augmentation</SectionHeader>
            <SubHeader>
              Roto VR Chair, Tobii Eye Tracking &amp; Analytics, Brain Computer
              Interface, Haptic Gloves
            </SubHeader>
          </a>
          <a
            href="https://www.arsnet.io/toolbox"
            target="_blank"
            rel="noreferrer"
            className="left-cloud cloud"
          >
            <div className="tall-cloud-image flip-x">
              <Image src={leftImage} />
            </div>
            <SectionHeader>
              Graphics Workstations &amp; Peripherals
            </SectionHeader>
            <SubHeader>
              Falcon Northwest Tiki, Acer, Bose, Logitech, NVIDIA GeForce RTX
            </SubHeader>
          </a>
          <div className="branches">
            <Image src={branchesLargeImage} />
          </div>
        </div>
      </EquipmentSection>
      <DonorListSection>
        <SectionHeader>Our Donors</SectionHeader>
        <BodyText>
          Thanks to our growing group of Art Reality Studio donors, you help to
          make ARS a posssiblity.
        </BodyText>
        {visionariesSection}
        {futuristsSection}
        {supportersSection}
        {friendsSection}
      </DonorListSection>
    </>
  );
};

GetInvolvedPageTemplate.propTypes = {};

const GetInvolvedPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <GetInvolvedPageTemplate
        title={frontmatter.title}
        totalDonated={frontmatter.totalDonated}
        visionaries={frontmatter.visionaries}
        futurists={frontmatter.futurists}
        supporters={frontmatter.supporters}
        friends={frontmatter.friends}
      />
    </Layout>
  );
};

GetInvolvedPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default GetInvolvedPage;

export const pageQuery = graphql`
  query GetInvolvedPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "getinvolved-page" } }) {
      frontmatter {
        title
        totalDonated
        visionaries {
          name
        }
        supporters {
          name
        }
        futurists {
          name
        }
        friends {
          name
        }
      }
    }
  }
`;
